import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import HeroIcon from '@components/atoms/hero-icon';
import style from './style.module.css';

interface DrawerProps {
  children: React.ReactNode;
  openDrawer: boolean;
  setOpenDrawer: (key: boolean) => void;
}

function Drawer({ children, openDrawer, setOpenDrawer }: DrawerProps) {
  return (
    <Transition.Root show={openDrawer} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto z-40" onClose={() => setOpenDrawer(false)}>
        <div className={style.TotalLayoutDrawer}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={style.OverlayDrawer} />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter={style.SecondTransitionChildPosition}
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave={style.SecondTransitionChildPosition}
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className={style.LayoutDrawerContainer}>
              <div className="mb-6 cursor-pointer" onClick={() => setOpenDrawer(false)}>
                <HeroIcon icon="XMarkIcon" extraClassNames="h-6 w-6 text-gray-700" />
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Drawer;

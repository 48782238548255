import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_INDICATIVE_PRICES_BY_SUBMARKET } from '@hooks/indicative-prices/queries/get-by-submarket/query';
import {
  GetIndicativePricesBySubmarketData,
  OrderDirectionType,
} from '@hooks/indicative-prices/queries/get-by-submarket/types';
import parsePayload from './parser';
import { SubmarketType } from '@hooks/indicative-prices/types';

export const useGetIndicativePricesBySubmarket = (isAdmin: boolean) => {
  if (isAdmin) {
    const [page, setPage] = useState<number>(1);
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [submarket, setSubmarket] = useState<SubmarketType | string>(SubmarketType.SOUTHEAST);
    const [orderBy, setOrderBy] = useState<string>('createdAt');
    const [orderDirection, setOrderDirection] = useState<OrderDirectionType>('desc');

    const { data, error, loading, refetch } = useQuery<GetIndicativePricesBySubmarketData>(
      GET_INDICATIVE_PRICES_BY_SUBMARKET,
      {
        variables: { filter: { page, pageLimit, submarket, orderBy, orderDirection } },
      },
    );

    return {
      indicativePricesBySubmarket: parsePayload(data?.getIndicativePricesBySubmarket.data || []),
      indicativePricesBySubmarketRawData: data?.getIndicativePricesBySubmarket.data || [],
      queryTotal: data?.getIndicativePricesBySubmarket.total || 0,
      error,
      loading,
      page,
      setPage,
      pageLimit,
      setPageLimit,
      submarket,
      setSubmarket,
      orderBy,
      setOrderBy,
      orderDirection,
      setOrderDirection,
      refetch,
    };
  } else {
    return {
      indicativePricesBySubmarket: [],
      indicativePricesBySubmarketRawData: [],
      queryTotal: 0,
      error: null,
      loading: false,
      page: 0,
      setPage: () => {},
      pageLimit: 0,
      setPageLimit: () => {},
      submarket: '',
      setSubmarket: () => {},
      orderBy: '',
      setOrderBy: () => {},
      orderDirection: 'desc',
      setOrderDirection: () => {},
    };
  }
};

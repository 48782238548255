import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import {
  CREATE_BID_PATH,
  CREATE_PROPOSAL_PATH,
  VIEW_PROPOSAL_LIST_PATH,
  INDICATIVE_PRICES_DASHBOARD,
  SEND_INDICATIVE_PRICES,
  EDIT_PROPOSAL_PATH,
} from './constants';
import { Layout } from '@components/layout';

import PrivateRoute from './private-route';
import CreateProposalPage from '@pages/mcp-create-proposal';
import EditProposalPage from '@pages/mcp-edit-proposal';
import McpProposalsPage from '@pages/mcp-proposals-page';
import CreateBidPage from '@pages/mcp-create-bid';
import SendIndicativePricesPage from '@pages/indicative-prices/send-prices';
import IndicativePricesDashboardPage from '@pages/indicative-prices/dashboard';
import TraderRoute, { TraderProvider } from './trader-route';
import NotFound404 from '@pages/404';
import McpProposalProvider from '@src/context/mcp-proposal';

const MainRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Layout.Root>
              <Outlet />
            </Layout.Root>
          </PrivateRoute>
        }
      >
        <Route path="/" element={<Navigate to={VIEW_PROPOSAL_LIST_PATH} />} />
        <Route
          path={VIEW_PROPOSAL_LIST_PATH}
          element={
            <McpProposalProvider>
              <McpProposalsPage />
            </McpProposalProvider>
          }
        />

        <Route path={CREATE_PROPOSAL_PATH} element={<CreateProposalPage />} />
        <Route path={`${EDIT_PROPOSAL_PATH}/:proposalId`} element={<EditProposalPage />} />
        <Route path={`${CREATE_BID_PATH}/:proposalId`} element={<CreateBidPage />} />
        <Route path={INDICATIVE_PRICES_DASHBOARD} element={<IndicativePricesDashboardPage />} />

        {/* ----------- Trader routes ----------- */}
        <Route
          element={
            <TraderRoute>
              <TraderProvider>
                <Outlet />
              </TraderProvider>
            </TraderRoute>
          }
        >
          <Route path={SEND_INDICATIVE_PRICES} element={<SendIndicativePricesPage />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default MainRouter;

import InputLabel from './label';
import Select from './select';
import InputSelectRoot from './select-root';
import ErrorMessage from './error-message';

export const InputSelect = {
    Root: InputSelectRoot,
    Input: Select,
    Label: InputLabel,
    Error: ErrorMessage,
};

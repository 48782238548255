import React from 'react';

import ButtonComponent from '@components/atoms/button';
import Drawer from '@components/atoms/drawer';
import Text from '@components/atoms/text';
import FilterSection from '@components/molecules/filter-section';

import style from './style.module.css';
import { Submarket, TransactionType, EnergyType, Status } from '@hooks/create-mcp-proposal/parser';
import { Filters } from '@pages/mcp-proposals-page';
interface FilterDrawerProposal {
  actions: {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  onClickCancelAction: () => void;
  onClickAction: () => void;
  loading: boolean;
  ownership: string | null;
  setOwnership: React.Dispatch<React.SetStateAction<string | null>>;
  traderId: string | null;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filters: Filters;
}

const FilterDrawer: React.FC<FilterDrawerProposal> = ({
  actions,
  onClickCancelAction,
  onClickAction,
  loading,
  ownership,
  setOwnership,
  traderId,
  setFilters,
  filters,
}) => {
  const handleCheckboxChangeOwnership = (id: string | null) => {
    ownership == id ? setOwnership('') : setOwnership(id);
  };

  const handleCheckboxChangeStatus = (newStatus: Status) => {
    filters.status == newStatus
      ? setFilters((prev) => ({ ...prev, status: null }))
      : setFilters((prev) => ({ ...prev, status: newStatus }));
  };

  const handleCheckboxChangeContract = (newTransaction: TransactionType) => {
    filters.transactionType == newTransaction
      ? setFilters((prev) => ({ ...prev, transactionType: null }))
      : setFilters((prev) => ({ ...prev, transactionType: newTransaction }));
  };

  const handleCheckboxChangeVolume = (value: { volMin: number; volMax: number }) => {
    let updatedArray = [...filters.volume];

    if (!updatedArray.some((lastValue) => lastValue.volMin === value.volMin)) {
      updatedArray = [value, ...updatedArray];
    } else {
      updatedArray = updatedArray.filter((item) => item.volMin !== value.volMin);
    }

    setFilters((prev) => ({
      ...prev,
      volume: updatedArray,
    }));
  };

  const handleCheckboxChangeSubmarket = (value: Submarket) => {
    let newCheckedItems = [...filters.submarket];

    if (!newCheckedItems.includes(value)) {
      newCheckedItems = [value, ...newCheckedItems];
    } else {
      newCheckedItems = newCheckedItems.filter((item) => item !== value);
    }

    setFilters((prev) => ({
      ...prev,
      submarket: newCheckedItems,
    }));
  };

  const handleCheckboxChangeEnergyType = (value: EnergyType) => {
    let newCheckedItems = [...filters.energyType];

    if (!newCheckedItems.includes(value)) {
      newCheckedItems = [value, ...newCheckedItems];
    } else {
      newCheckedItems = newCheckedItems.filter((item) => item !== value);
    }

    setFilters((prev) => ({
      ...prev,
      energyType: newCheckedItems,
    }));
  };

  const handleCheckboxIsCheck = (list: string[], value: string) => list.includes(value);

  return (
    <Drawer openDrawer={actions.isOpen} setOpenDrawer={actions.setIsOpen}>
      <Text size="text-heading-medium" weight="font-bold">
        Filtros
      </Text>

      <FilterSection
        title="Titularidade"
        filters={[
          {
            label: 'Minhas cotações',
            isChecked: ownership == traderId,
            onCheckedChange: () => handleCheckboxChangeOwnership(traderId),
          },
          {
            label: 'Todas as cotações',
            isChecked: ownership == null,
            onCheckedChange: () => handleCheckboxChangeOwnership(null),
          },
        ]}
        className={style.flexItens}
      />

      <FilterSection
        title="Status"
        filters={[
          {
            label: 'Concluído',
            isChecked: filters.status == 'FINISHED',
            onCheckedChange: () => handleCheckboxChangeStatus('FINISHED'),
          },
          {
            label: 'Em andamento',
            isChecked: filters.status == 'ACTIVE',
            onCheckedChange: () => handleCheckboxChangeStatus('ACTIVE'),
          },
        ]}
        className={style.flexItens}
      />

      <FilterSection
        title="Operação"
        filters={[
          {
            label: 'Compra',
            isChecked: filters.transactionType == 'SALE',
            onCheckedChange: () => handleCheckboxChangeContract('SALE'),
          },
          {
            label: 'Venda',
            isChecked: filters.transactionType == 'PURCHASE',
            onCheckedChange: () => handleCheckboxChangeContract('PURCHASE'),
          },
        ]}
        className={style.flexItens}
      />

      <FilterSection
        title="Volume"
        filters={[
          {
            label: '100 MWh - 300 MWh',
            isChecked: filters.volume.some((volume) => volume.volMin === 100),
            onCheckedChange: () => handleCheckboxChangeVolume({ volMin: 100, volMax: 300 }),
          },
          {
            label: '301 MWh - 500 MWh',
            isChecked: filters.volume.some((volume) => volume.volMin === 301),
            onCheckedChange: () => handleCheckboxChangeVolume({ volMin: 301, volMax: 500 }),
          },
          {
            label: '501 MWh - 700 MWh',
            isChecked: filters.volume.some((volume) => volume.volMin === 501),
            onCheckedChange: () => handleCheckboxChangeVolume({ volMin: 501, volMax: 700 }),
          },
          {
            label: '701 MWh - 1000 MWh',
            isChecked: filters.volume.some((volume) => volume.volMin === 701),
            onCheckedChange: () => handleCheckboxChangeVolume({ volMin: 701, volMax: 1000 }),
          },
          {
            label: 'Mais de 1000 MWh',
            isChecked: filters.volume.some((volume) => volume.volMin === 1000),
            onCheckedChange: () => handleCheckboxChangeVolume({ volMin: 1000, volMax: 0 }),
          },
        ]}
      />

      <FilterSection
        title="Submercado"
        filters={[
          {
            label: 'SE/CO',
            isChecked: handleCheckboxIsCheck(filters.submarket, 'SOUTHEAST'),
            onCheckedChange: () => handleCheckboxChangeSubmarket('SOUTHEAST'),
          },
          {
            label: 'S',
            isChecked: handleCheckboxIsCheck(filters.submarket, 'SOUTH'),
            onCheckedChange: () => handleCheckboxChangeSubmarket('SOUTH'),
          },
          {
            label: 'N',
            isChecked: handleCheckboxIsCheck(filters.submarket, 'NORTH'),
            onCheckedChange: () => handleCheckboxChangeSubmarket('NORTH'),
          },
          {
            label: 'NE',
            isChecked: handleCheckboxIsCheck(filters.submarket, 'NORTHEAST'),
            onCheckedChange: () => handleCheckboxChangeSubmarket('NORTHEAST'),
          },
        ]}
        className={style.flexItens}
      />

      <FilterSection
        title="Tipo de energia"
        filters={[
          {
            label: '100% incentivada',
            isChecked: handleCheckboxIsCheck(filters.energyType, 'INCENTIVIZED_100'),
            onCheckedChange: () => handleCheckboxChangeEnergyType('INCENTIVIZED_100'),
          },
          {
            label: '50% incentivada',
            isChecked: handleCheckboxIsCheck(filters.energyType, 'INCENTIVIZED_50'),
            onCheckedChange: () => handleCheckboxChangeEnergyType('INCENTIVIZED_50'),
          },
          {
            label: 'Convencional',
            isChecked: handleCheckboxIsCheck(filters.energyType, 'CONVENTIONAL'),
            onCheckedChange: () => handleCheckboxChangeEnergyType('CONVENTIONAL'),
          },
        ]}
        className={style.flexItens}
      />

      <div className="flex flex-col gap-2 mt-8 md:flex-row">
        <ButtonComponent kind="primary" label="Filtrar" size="2" onClick={onClickAction} loading={loading} />
        <ButtonComponent kind="secondary" label="Cancelar" size="2" onClick={onClickCancelAction} />
      </div>
    </Drawer>
  );
};
export default FilterDrawer;
